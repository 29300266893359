<template>
  <div class="main-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item>
          <i class="icon-home"></i>卡系管理
        </Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="header">
      <ul class="header-nav">
        <li>
          <router-link to="/base/card/overview">卡系情况</router-link>
        </li>
        <li>
          <router-link to="/base/card/application">卡系审核</router-link>
        </li>
        <li>
          <router-link to="/base/card/service">服务评价</router-link>
        </li>
        <li>
          <router-link to="/base/card/dispatch">派单系统</router-link>
        </li>
      </ul>
    </div>
    <router-view></router-view>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';

export default {
	components: {
		'v-title': vTitle,
	},
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  height: 44px;
  line-height: 44px;
  padding: 0 20px;
  &-nav {
    font-size: 16px;
    li {
      display: inline-block;
      margin-right: 20px;
      a {
        color: #000;
        &.active {
          color: #fdad00;
        }
      }
    }
  }
}
</style>
